<template>
    <a-modal :visible="visible" :title="options.title" :maskClosable="false" :confirmLoading="confirmLoading" :width="1200" @cancel="cancelHandel">
        <a-row>
            <a-col :xs="24" :md="24" :style="{ height: '600px' }">
                <vue-cropper
                    ref="cropper"
                    :img="options.img"
                    :info="true"
                    :autoCrop="options.autoCrop"
                    :autoCropWidth="options.autoCropWidth"
                    :autoCropHeight="options.autoCropHeight"
                    :fixedBox="options.fixedBox"
                    :original="options.original"
                    :outputSize="options.outputSize"
                    :full="options.full"
                    :mode="cropperMode"
                    :outputType="options.outputType"
                    @realTime="realTime"
                >
                </vue-cropper>
            </a-col>
            <!-- <a-col :xs="24" :md="12" :style="{ height: '250px' }">
              <div
                :class="
                  options.previewsCircle
                    ? 'avatar-upload-preview'
                    : 'avatar-upload-preview_range'
                "
              >
                <img :src="previews.url" :style="previews.img" />
              </div>
            </a-col> -->
        </a-row>
        <template slot="footer">
            <a-button key="back" @click="cancelHandel">取消</a-button>
            <a-button key="submit" type="primary" :loading="confirmLoading" @click="okHandel">保存</a-button>
        </template>
    </a-modal>
</template>

<script>
import axios from "axios";
import {domainName} from "@/config/index";

export default {
    props: {
        //图片存储在oss上的上级目录名
        imgType: {
            type: String,
            default: "",
        },
        fileName: {
            type: String, // 文件名
            default: "",
        },
        cropperMode: {  // 图片渲染方式
            type: String,
            default: 'contain'
        }
    },
    data() {
        return {
            visible: false,
            img: null,
            confirmLoading: false,
            options: {
                img: "", //裁剪图片的地址
                autoCrop: true, //是否默认生成截图框
                autoCropWidth: 180, //默认生成截图框宽度
                autoCropHeight: 180, //默认生成截图框高度
                fixedBox: true, //是否固定截图框大小 不允许改变
                previewsCircle: false, //预览图是否是原圆形
                original: true,     //上传图片按照原始比例渲染
                outputType:'png',
                title: "修改图片",
            },
            previews: {},
            url: {
                upload: "/sys/common/saveToImgByStr",
            },
        };
    },
    methods: {
        edit(record) {
            const {options} = this;
            this.visible = true;
            this.options = Object.assign({}, options, record);
        },
        // 取消截图
        cancelHandel() {
            this.confirmLoading = false;
            this.visible = false;
            this.$emit("cropper-no");
        },
        // 确认截图
        okHandel() {
            const that = this;
            that.confirmLoading = true;
            // 获取截图的base64 数据
            this.$refs.cropper.getCropData((data) => {
                let file = this.dataURLtoFile(data, this.fileName);
                let formData = new window.FormData();
                formData.append("uploadFile", file);
                axios.post(`${domainName}/admin/files/upload/images`, formData)
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.visible = false;
                            this.confirmLoading = false;
                            this.$emit("cropper-ok", res.data.data);
                        }
                    });
            });
        },
        //移动框的事件
        realTime(data) {
            this.previews = data;
        },
        // base 64 转成二进制文件流
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },
    },
};
</script>

<style lang="less" scoped>
.avatar-upload-preview_range,
.avatar-upload-preview {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;

    img {
        background-color: red;
        height: 100%;
    }
}

.avatar-upload-preview_range {
    border-radius: 0;
}
</style>
