<template>
    <!-- 新建banner页面 -->
    <div class="positionT0L0">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
            <a-form-model-item label="banner名称" prop="name" class="por">
                <a-input v-model="form.name" placeholder="请输入banner名称" :maxLength="32">
                    <div slot="addonAfter">{{ form.name.length }}/32</div>
                </a-input>
            </a-form-model-item>
            <a-form-model-item label="banner分类" prop="categoryNo">
                <a-select show-search v-model="form.categoryNo" placeholder="banner分类" :disabled="disabled" option-filter-prop="children" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="CateNoChange">
                    <a-select-option :value="item.CateNo" v-for="item in lessonArr" :key="item.CateNo">{{ item.CateName }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="banner图片" :class="{ 'has-error': !form.imageUrl && isShowImag }" required>
                <CropperUpload :options="coverOptions" :imageUrl="form.imageUrl" @avatarfn="imageUrlFn" avatarTextTop="应用于首页" avatarTextBottom="建议尺寸686*250px,JPG.PNG.JPEG格式 图片小于1M"/>
                <div class="ant-form-explain" v-if="!form.imageUrl && isShowImag">请上传banner图片</div>
            </a-form-model-item>
            <a-form-model-item label="banner详情" prop="targetType" :wrapper-col="{ span: 16 }">
                <a-radio-group name="radioGroup" :value="form.targetType" @change="targetTypeChange">
                    <a-radio :value="1">商品</a-radio>
                    <a-radio :value="2">跳转链接</a-radio>
                    <a-radio :value="3">自定义内容</a-radio>
                    <a-radio :value="4">会员详情</a-radio>
                    <a-radio :value="5">小程序</a-radio>
                    <a-radio :value="6">抖音</a-radio>
                </a-radio-group>
                <div v-if="form.targetType == 1">
                    <div class="product-box" v-if="isShowGoods">
                        <a-icon type="close-circle" :style="{ fontSize: '20px', color: '#9FA5AF' }"
                                class="product-close" @click="closeHandle"/>
                        <div class="product-wrap flexbox justify middle" :class="{ bg: this.$route.params.id != 0 }">
                            <div class="left-wrap flex1 flexbox left">
                                <div class="product-img">
                                    <img class="p-img" :src="goodsDetail.coverUrl" alt="图片"/>
                                </div>
                                <div class="product-info flex1">
                                    <div style="height: 56px">
                                        <div class="p-title more-t">{{ goodsDetail.businessName }}</div>
                                        <div v-show="form.businessType == 1 && goodsDetail.pricingType == 0"
                                             class="p-price">免费
                                        </div>
                                        <div v-show="form.businessType == 1 && goodsDetail.pricingType == 1"
                                             class="p-price">￥{{ goodsDetail.price }}
                                        </div>
                                        <div v-show="form.businessType == 1 && goodsDetail.pricingType == 2"
                                             class="p-price">不支持单独售卖
                                        </div>
                                        <div v-show="form.businessType == 2" class="p-price">￥{{ goodsDetail.price }}
                                        </div>
                                    </div>
                                    <div class="p-type">商品类型:{{ form.businessType == "1" ? "课程" : "会员" }}</div>
                                </div>
                            </div>
                            <div class="right-wrap">
                                <a-button type="primary" @click="editGoods">更换商品</a-button>
                            </div>
                        </div>
                    </div>
                    <a-button v-else type="primary" size="large" @click="addClick">添加商品</a-button>
                </div>
                <Ueditor v-show="form.targetType == 3" :content="form.description" @editorFn="editorFn"/>
            </a-form-model-item>
            <a-form-model-item ref="hrefUrl" label="链接" prop="hrefUrl" v-if="form.targetType == 2">
                <a-input placeholder="请输入跳转链接" v-model="form.hrefUrl" style="width: 500px"/>
            </a-form-model-item>
            <a-form-model-item ref="tiktokType" label="抖音" prop="tiktokType" v-if="form.targetType == 6">
                <a-select v-model="form.tiktokType" placeholder="请选择类型">
                    <a-select-option :value="1">直播间</a-select-option>
                    <a-select-option :value="2">用户主页</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item ref="text" label="ID" prop="text" v-if="form.targetType == 6">
                <a-input v-model="form.text" placeholder="请输入抖音ID"/>
            </a-form-model-item>
            <a-form-model-item ref="appid" label="小程序名称" prop="appid" v-if="form.targetType == 5">
                <a-select v-model="form.appid" placeholder="请选择小程序" style="width: 100%">
                    <a-select-option :value="item.id" v-for="item in appidList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item ref="appPath" label="小程序跳转路径" prop="appPath" v-if="form.targetType == 5">
                <a-input v-model="form.appPath" placeholder="请输入小程序跳转路径"/>
            </a-form-model-item>
            
            <a-form-model-item ref="sort" label="排序">
                <a-input-number placeholder="请输入排序" v-model="form.sort" style="width: 500px"/>
            </a-form-model-item>
    
            <a-form-model-item label="有效期" :rules="[{ required: true}]">
                <a-range-picker v-model="dateTime" show-time @change="dateTimeChange" />
                <span v-show="msgSize" style="color: red">请选择有效期</span>
            </a-form-model-item>
            
            <a-form-model-item ref="putawayFlag	" label="上架设置" prop="putawayFlag">
                <a-radio-group :value="form.putawayFlag" @change="putawayFlagChange">
                    <a-radio :style="radioStyle" :value="2">立即上架</a-radio>
                    <a-radio :style="radioStyle11" :value="1"> 定时上架</a-radio>
                    <template v-if="form.putawayFlag == 1">
                        <template>
                            <a-date-picker :locale="locale" :value="form.putawayTime ? form.putawayTime : null" :format="dateFormat" show-time placeholder="请选择上架时间" @change="putawayTimeChange" @ok="putawayTimeOk"/>
                        </template>
                    </template>
                    <a-radio :style="radioStyle" :value="3"> 暂不上架</a-radio>
                </a-radio-group>
            </a-form-model-item>
        </a-form-model>
        
        <FooterToolBar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
        </FooterToolBar>
        
        <RadioModal
            v-if="modalVisible"
            :modalVisible="modalVisible"
            :type="0"
            :tabType="form.businessType"
            :selectedRowKeys="selectedRowKeys"
            :isShowMember="true"
            @cancel="cancel"
            @ok="ok"
        />
    </div>
</template>

<script>
import {baseMixin} from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {message} from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import {format} from "@/utils/tools";
import {
    BannerGetBannerApiV2,
    NewBannerUpdBannerApi,
    GetCateListApi
} from "@/request/api/indexManage";
import CropperUpload from "@/components/file/CropperUpload";
import Ueditor from "@/components/ueditor/ueditor";
import RadioModal from "@/components/courseMember/RadioModal";
import { appidList } from '@/utils/constants'
export default {
    mixins: [baseMixin],
    components: {CropperUpload, FooterToolBar, Ueditor, RadioModal},
    created() {
        let id = this.$route.params.id;
        if (id !== "0") {
            // this.disabled=true
            this.isShowGoods = true;
            BannerGetBannerApiV2({id: id}).then(({code, data}) => {
                if (code == 200) {
                    this.form = data;
                    this.form.startTime = data.startTime
                    this.form.endTime = data.endTime
                    this.dateTime = [data.startTime,data.endTime]
                    this.form.id = this.$route.params.id;
                    this.goodsDetail.coverUrl = data.coverUrl;
                    this.goodsDetail.businessName = data.businessName;
                    this.goodsDetail.price = data.price;
                    this.tiktokType = data.tiktokType
                    this.form.targetType == 6 ? this.form.text = data.description : ''
                }
            });
        }
    },
    mounted() {
        GetCateListApi().then(({code, data}) => {
            if (code == 200) {
                this.lessonArr = data;
                this.lessonArr.splice(0, 0, {
                    CateName: "默认推荐",
                    cateStatus: "1",
                    CateNo: "0",
                    createTime: "",
                    name: "默认推荐",
                });
            }
        });
    },
    data() {
        return {
            selectedRowKeys: [],
            goodsDetail: {coverUrl: "", businessName: "", price: ""}, // 商品详情
            locale,
            isShowImag: false, // 是否显示图片提示
            isShowGoods: false, // 商品详情是否显示
            modalVisible: false, // 模态框是否显示隐藏
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            keepLoading: false, // 保存的按钮是否加载
            radioStyle: {
                display: "block",
                height: "30px",
                lineHeight: "30px",
            },
            appidList,
            coverOptions: {
                autoCrop: true, //是否默认生成截图框
                autoCropWidth: 686, //默认生成截图框宽度
                autoCropHeight: 250, //默认生成截图框高度
                fixedBox: true, //是否固定截图框大小 不允许改变
                previewsCircle: false, //预览图是否是原圆形
                title: "修改图片",
            },
            radioStyle11: {
                height: "30px",
                lineHeight: "30px",
            },
            dateTime:[],
            msgSize:false,
            form: {
                id: "",
                name: "", //
                imageUrl: "",
                imageCosKey: "",
                targetType: 1,
                hrefUrl: "", // 跳转链接
                description: "", // 富文本
                putawayTime: null,
                putawayFlag: 2,
                businessNo: "", // 业务编号 [如果是课程，请传courseNo 如果是会员，请传会员memberNo]
                businessType: 1, //业务类型[1: 课程 2: 会员]
                categoryNo: "0",
                appid: undefined,
                dithering: '',
                sort:'',
                tiktokType:undefined,
                text:'',
                startTime:'',
                endTime: '',
                appPath: '',
            },
            rules: {
                hrefUrl: [
                    {
                        required: true,
                        message: "请输入链接",
                        trigger: "blur",
                    },
                ],
                categoryNo: [
                    {
                        required: true,
                        message: "请选择分类",
                        trigger: "change",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "输入的内容不能为空",
                        trigger: "blur",
                    },
                    {
                        max: 32,
                        message: "长度最长为32个",
                        trigger: "blur",
                    },
                ],
                targetType: [
                    {
                        required: true,
                        message: "请选择banner详情",
                        trigger: "change",
                    },
                ],
                appid: [
                    {
                        required: true,
                        message: "请选择小程序",
                        trigger: "change",
                    },
                ],
                tiktokType: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: "change",
                    },
                ],
                text: [
                    {
                        required: true,
                        message: "请输入ID",
                        trigger: "blur",
                    },
                ],
            },
            lessonArr: [],
            disabled: false
        };
    },
    methods: {
        moment,
        dateTimeChange(val,dateString){
            console.log(val,dateString)
            this.form.startTime = dateString[0]
            this.form.endTime = dateString[1]
        },
        
        // 切换课程分类
        CateNoChange(value) {
            this.form.categoryNo = value;
        },
        // banner图片路径
        imageUrlFn(data) {
            this.form.imageUrl = data.url;
            this.form.imageCosKey = data.cosKey;
        },
        // banner详情修改的回调
        targetTypeChange(e) {
            this.form.description = ''
            this.form.text = ''
            this.form.hrefUrl = ''
            this.form.appid = undefined
            this.form.tiktokType = undefined
            if (e.target.value == 1) {
                this.isShowGoods = false;
            }
            this.form.targetType = e.target.value;
        },
        // 富文本的函数
        editorFn(value) {
            this.form.description = value;
        },
        // 上架设置改变的函数
        putawayFlagChange(e) {
            this.form.putawayFlag = e.target.value;
        },
        // 上架时间发生发生改变的回调
        putawayTimeChange(value, dateString) {
            this.form.putawayTime = value;
        },
        // 上架时间OK的回调
        putawayTimeOk(value) {
            this.form.putawayTime = value;
        },
        // 添加商品的按钮
        addClick() {
            this.modalVisible = true;
        },
        // 更换商品的按钮
        editGoods() {
            this.modalVisible = true;
        },
        // 商品关闭按钮
        closeHandle() {
            this.goodsDetail = null;
            this.isShowGoods = false;
        },
        // 模态框的取消按钮
        cancel() {
            this.modalVisible = false;
        },
        // 模态框的确定按钮
        ok(SelectedRowKeys, obj, businessType) {
            if (obj.memberNo || obj.key) {
                if (obj.memberNo) {
                    this.form.businessNo = obj.memberNo;
                    this.$set(this.goodsDetail, "businessName", obj.memberName);
                    this.$set(this.goodsDetail, "coverUrl", obj.coverImg);
                    this.$set(this.goodsDetail, "price", obj.price);
                } else {
                    this.form.businessNo = obj.key;
                    this.goodsDetail = obj;
                    this.$set(this.goodsDetail, "businessName", obj.name);
                    this.$set(this.goodsDetail, "coverUrl", obj.coverUrl);
                    this.$set(this.goodsDetail, "price", obj.price);
                }
                this.form.businessType = Number(businessType);
                this.modalVisible = false;
                this.isShowGoods = true;
            } else {
                this.modalVisible = false;
            }
        },
        // 保存的按钮
        keepClick() {
            this.form.putawayTime = format(
                new Date(this.form.putawayTime),
                "yyyy-MM-dd HH:mm:ss"
            );
            this.keepLoading = true;
            setTimeout(() => {
                this.keepLoading = false;
            }, 3000);
            if (!this.form.imageUrl) {
                this.isShowImag = true;
            }
            if (
                this.form.putawayTime == "1970-01-01 08:00:00" ||
                this.form.putawayTime == "NaN-aN-aN aN:aN:aN"
            ) {
                this.form.putawayTime = "";
            }
            if (this.form.targetType == 1) {
                if (!this.isShowGoods) {
                    message.error("请添加商品"); // 没有添加商品情况下
                    return;
                }
            }
            
            if(!this.form.startTime){
                this.msgSize = true
                return false
            }else{
                this.msgSize = false
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.form.putawayFlag != 0) {
                        if (this.$route.params.id != 0) {
                            this.form.tiktokType == undefined ? '' : this.form.tiktokType
                            this.form.appid == undefined ? '' : this.form.appid
                            this.form.targetType == 6 ? this.form.description = this.form.text : this.form.description
                            NewBannerUpdBannerApi(this.form).then(({code}) => {
                                if (code == 200) {
                                    message.success("修改成功");
                                    location.href = "/indexManage/indexBannerList";
                                }
                            });
                        } else {
                            this.form.targetType == 6 ? this.form.description = this.form.text : this.form.description
                            this.form.tiktokType == undefined ? '' : this.form.tiktokType
                            this.form.appid == undefined ? '' : this.form.appid
                            NewBannerUpdBannerApi(this.form).then(({code}) => {
                                if (code == 200) {
                                    message.success("新增成功");
                                    location.href = "/indexManage/indexBannerList";
                                }
                            });
                        }
                    } else {
                        message.error("请选择上架设置");
                    }
                } else {
                    message.error("还有必填项未完成");
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.form_span {
    top: -4px;
}

/deep/ .ant-radio-group {
    margin-bottom: 20px;
}

/deep/ .ant-form-item-control {
    //line-height: unset;
}

.product-box {
    position: relative;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 4px;
    
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    
    .product-wrap {
        height: 86px;
        
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            
            .product-info {
                flex-direction: column;
                height: 86px;
                
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                
                .p-price {
                    color: red;
                }
                
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}

/deep/ .product .ant-form-item-control {
    //line-height: normal;
}

/deep/ .edu-type .ant-form-item-children {
    display: flex;
}

/deep/ .edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}

/deep/ .edu-quantity .ant-input-number {
    width: 100%;
}

/deep/ .date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}

/deep/ .ant-form-item-control {
    //line-height: 22px;
}
</style>
