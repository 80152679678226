import { render, staticRenderFns } from "./RadioModal.vue?vue&type=template&id=4ff8ad49&scoped=true&"
import script from "./RadioModal.vue?vue&type=script&lang=js&"
export * from "./RadioModal.vue?vue&type=script&lang=js&"
import style0 from "./RadioModal.vue?vue&type=style&index=0&id=4ff8ad49&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff8ad49",
  null
  
)

export default component.exports